<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

input{
  width: 100%;
  font-size: 24px;
  padding: 5px 0;
  text-align: center;
  margin: auto;
}

.question-input{
  position: relative;
  width: 80%;
  margin: auto;
  padding: 0 0 25px 0;
}

.button{
  width: 80%;
  margin: auto;
  border: 1px solid rgb(190,190,190);
  padding: 10px;
  font-size: 24px;
  border-radius: 7px;
  cursor: pointer;
}
</style>
