<template>
  <div class="question" :class="{ selected : question && question.selected == 1 }">
    <h3>{{ question.question }}</h3>
    <div v-if="question && question.selected == 0 && !adminid" class = "votes">
      <div class = "action upvote" @click="upVoteQuestion()" title="Up Vote">
        <p :title="question.votes">{{ question.votes > 999 ? (question.votes/1000).toFixed(1) + "k" : question.votes }}</p>
      </div>
      <div v-if="question.flag_cleared == 0" class = "action report" @click="flagQuestion()" title="Flag">
        <img :src="require('@/assets/flag.svg')" />
      </div>
    </div>
    <div v-if="adminid" class = "votes">
      <div class = "action upvote" @click="setSelection()" :title="selected ? 'Unselect' : 'Select'">
      </div>
      <div class = "action remove" @click="removeSelection()" :title="Remove">
        <img :src="require('@/assets/trash.svg')" />
      </div>
      <div v-if="question && question.flagged == 1 && question.flag_cleared == 0" class = "action report" @click="clearFlag()" title="Clear Flag">
        <img :src="require('@/assets/flag.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionComponent',
  props:{ question : Object, adminid : String },
  methods:{
    upVoteQuestion(){
      fetch("https://www.nobadquestions.meme:7800/api/upvote", { method: "POST", headers : { question : JSON.stringify(this.question) }})
    },
    flagQuestion(){
      fetch("https://www.nobadquestions.meme:7800/api/flag", { method: "POST", headers : { question : JSON.stringify(this.question) }})
    },
    setSelection(){
      fetch("https://www.nobadquestions.meme:7800/adminapi/selectquestion", { method: "POST", headers : { question : JSON.stringify(this.question), adminid : this.adminid, setting : this.question.selected == 0 ? 1 : 0 }})
    },
    removeSelection(){
      fetch("https://www.nobadquestions.meme:7800/adminapi/removequestion", { method: "POST", headers : { question : JSON.stringify(this.question), adminid : this.adminid }})
    },
    clearFlag(){
      fetch("https://www.nobadquestions.meme:7800/adminapi/flagcleared", { method: "POST", headers : { question : JSON.stringify(this.question), adminid : this.adminid, setting : this.question.flag_cleared == 0 ? 1 : 0 }})
    }
  }
}
</script>

<style scoped>
h3{
  width: 90%;
  font-size: 22px;
}
img{
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.question{
  width: 80%;
  margin: 10px auto;
  border: 1px solid rgb(190,190,190);
  padding: 10px;
  font-size: 24px;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
}
.votes{
  width: 10%;
  position: relative;
}

.action{
  width: 100%;
  height: 50%;
  padding: 5px;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.upvote{
  border-radius: 0 5px 0 0;
  margin-top: -10px;
  background-color: rgb(120, 255, 180);
}
.report{
  background-color: rgb(255, 125, 125);
  border-radius: 0 0 5px 0;
}

.remove{
  position: absolute;
  left: 110%;
  bottom: 0%;
  height: 80%;
  width: 20%;
  border-radius: 7px;
  background-color: rgb(255, 125, 125);
}

.action p{
  color: rgb(100,100,100);
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 18px;
  transform: translate(-50%, 40%);
}

.selected{
  background-color: rgb(230,255,230);
}
</style>