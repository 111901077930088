<template>
  <div>
    <h1>There are no bad questions...</h1>
    <div v-if="todaysquestions.length >= maxquestions">
      <h3>Max questions have been asked for this voting period, vote on your favorites below and try again tomorrow!</h3>
      <h3>Ko-fi members get to ask questions directly on the membership page - <a href="https://ko-fi.com/nobadquestions" target="_blank">Join Us There</a>!</h3>
      <h3>Or you can always skip to the top by asking your question directly - <a href="https://ko-fi.com/nobadquestions/commissions" target="_blank">For a one time fee</a></h3>
    </div>
    <div v-else>
      <h3> {{ todaysquestions.length }} / {{ maxquestions }} Questions Asked</h3>
      <div class="question-input">
        <input type="textbox" placeholder="What is yours?" v-model="questiontext"/>
        <div class="questionlimit" :class="{ overlimit : maxlimit - questiontext.length < 1 }">
          {{maxlimit - questiontext.length}} / {{ maxlimit }}
        </div>
      </div>
      <div class = "button" @click="submitQuestion()">Submit</div>
    </div>
    <h3 v-if="lastvideourl">Last Video:</h3>
    <iframe v-if="lastvideourl" width="560" height="315" :src="lastvideourl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div v-else class = "youtube-widget"><a href="https://www.youtube.com/channel/UClFplzomDUg7R5Mb36MU68A" target="_blank">Questions Are Answered Here!</a></div>
    <div class = "question-container">
      <QuestionComponent v-for="question in todaysquestions" :key="question.id" :question="question" />
    </div>

  </div>
</template>

<script>
import QuestionComponent from '@/components/QuestionComponent.vue'

export default {
  name: 'HomeView',
  components:{
    QuestionComponent
  },
  data : function(){
    return{
      maxlimit: 260,
      maxquestions: 50,
      lastvideourl: "",
      questiontext: "",
      todaysquestions: [],
      latestvideourl: ""
    }
  },
  created: function(){
    this.getSettings();
    this.getTodaysQuestions();
    setInterval(()=> {this.getTodaysQuestions()}, 2000);
  },
  methods:{
    getTodaysQuestions(){
      this.getSettings()
      fetch("https://www.nobadquestions.meme:7800/api/gettodaysquestions", { method: "POST" })
        .then( res => res.json() )
        .then( res => {
          this.todaysquestions = res.results;
        });
    },
    getSettings(){
      fetch("https://www.nobadquestions.meme:7800/api/getsettings", { method: "POST" })
        .then( res => res.json() )
        .then( res => {
          this.maxlimit = res.results[0].maxcharacters;
          this.maxquestions = res.results[0].maxquestions;
          this.currentday = res.results[0].currentday;
          this.lastvideourl = res.results[0].lastvideourl;
        });
    },
    submitQuestion(){
      fetch("https://www.nobadquestions.meme:7800/api/addquestion", { method: "POST", headers : { question : this.questiontext } })
        .then( () => {
          this.questiontext = "";
        });
    }
  }
}
</script>

<style scoped>
.questionlimit{
  position: absolute;
  right: 0;
  bottom: 0;
}

.overlimit{
  text-shadow: 1px 1px 5px #f11, 1px 1px 5px #f11;
  color: #f11;
}
.youtube-widget{
  background-color: #f11;
  margin: 10px auto;
  width: fit-content;
  height: fit-content;
  border-radius: 13px;
  padding: 20px;
}

.youtube-widget a{
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 24px;
}
</style>